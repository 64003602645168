import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" width="208" height="208" viewBox="0 0 208 208">
    <g transform="translate(4 4)">
      <g transform="translate(-47 -66)">
        <path
          d="M100,0A100,100,0,1,1,0,100,100,100,0,0,1,100,0Z"
          transform="translate(47 66)"
          fill="#0a192f"
        />

        <path
          style={{ opacity: 0 }}
          id="text"
          d="M47.344-28.477l1.7-4.219H63.457l1.7,4.219Zm-3.281,8.438L45.7-24.258H66.738l1.816,4.219ZM40.781-11.6l1.641-4.219H70.078L71.836-11.6ZM6.5,0H1.934L40.313-97.031h4.57L83.2,0H78.691L42.6-91.348ZM43.125-81.328l2.4,5.391L15.527,0H11.016ZM47.813-70.2l2.227,5.8L24.668,0H20.1Zm4.453,11.6,2.227,5.918L33.691,0H29.18ZM110.449,0h-4.57L67.559-97.031H72.07ZM62.988-97.031,101.367,0H96.855L58.477-97.031Zm-9.082,0L92.285,0h-4.57L49.395-97.031Zm68.438,75.938h-7.207v-4.219h7.207a6.737,6.737,0,0,0,5.215-2.344,8.933,8.933,0,0,0,2.109-6.211V-97.031h4.219v63.164a12.96,12.96,0,0,1-3.34,9.17A10.749,10.749,0,0,1,122.344-21.094Zm0,8.438h-7.207v-4.219h7.207a14.635,14.635,0,0,0,11.162-4.98,17.076,17.076,0,0,0,4.6-12.012V-97.031h4.219v63.164a21.13,21.13,0,0,1-5.83,15A18.689,18.689,0,0,1,122.344-12.656Zm0,8.438h-7.207V-8.437h7.207a22.747,22.747,0,0,0,17.139-7.441,25.188,25.188,0,0,0,7.061-17.988V-97.031h4.219v63.164a30.163,30.163,0,0,1-3.779,14.854A28.646,28.646,0,0,1,136.641-8.2,27.041,27.041,0,0,1,122.344-4.219Zm0,8.438h-7.207V0h7.207a30.835,30.835,0,0,0,12.715-2.7,32.816,32.816,0,0,0,10.4-7.236A34.151,34.151,0,0,0,152.4-20.742a34.18,34.18,0,0,0,2.578-13.125V-97.031H159.2v63.164A38.345,38.345,0,0,1,156.3-19.1a38.338,38.338,0,0,1-7.852,12.158A36.941,36.941,0,0,1,136.67,1.2,35.051,35.051,0,0,1,122.344,4.219Z"
          transform="translate(64 212)"
          fill="#64ffda"
        />
      </g>
    </g>
    <g
      transform="translate(-40 -60) rotate(-90)"
      style={{ transformBox: 'fill-box', transformOrigin: 'center' }}>
      <circle
        id="B"
        cx="146"
        cy="165"
        r="100"
        stroke="#64ffda"
        strokeWidth="4"
        // stroke-dasharray= "650"
        strokeDasharray="650"
        strokeDashoffset="650"
      />
    </g>
  </svg>
);

export default IconLoader;
